import _CSSTransition2 from "./CSSTransition";
import _ReplaceTransition2 from "./ReplaceTransition";
import _TransitionGroup2 from "./TransitionGroup";
import _Transition2 from "./Transition";
var exports = {};

var _CSSTransition = _interopRequireDefault(_CSSTransition2);

var _ReplaceTransition = _interopRequireDefault(_ReplaceTransition2);

var _TransitionGroup = _interopRequireDefault(_TransitionGroup2);

var _Transition = _interopRequireDefault(_Transition2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports = {
  Transition: _Transition.default,
  TransitionGroup: _TransitionGroup.default,
  ReplaceTransition: _ReplaceTransition.default,
  CSSTransition: _CSSTransition.default
};
export default exports;
export const Transition = exports.Transition;